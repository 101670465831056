@tailwind base;
@tailwind components;
@tailwind utilities;

.flex-basis-0 {
    flex-basis: 0;
}

.show-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background: #ddd;
}

.show-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

.break-before {
    break-before: page;
}

.break-inside-avoid {
    break-inside: avoid;
}

.fadeout {
  mask-image: linear-gradient(to bottom, black 20%, transparent 70%);
  -webkit-mask-image: linear-gradient(to bottom, black 20%, transparent 70%);
}

.prose {
    line-height: 1.25em;
    max-width: 100%;
    font-size: 0.85em;
}

@media (min-width: 640px) {
    .prose {
        font-size: 1em;
    }
}

.prose * {
    margin: 0;
}

.prose ol {
    list-style-type: decimal;
    margin-left: 2em;
}

.prose ul {
    list-style-type: disc;
    margin-left: 2em;
}

.prose ol li::marker, .prose ul li::marker {
    color: var(--tw-prose-body);
}
